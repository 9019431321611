<template>
  <footer>
    <div class="container">
      <el-row>
        <el-col :span="24">
          <div class="sm-top_footer">
            <el-row>
              <el-col :span="12">
                <div class="block_logo">
                  <div class="footer_logo_wrap logo">
                    <router-link to="/"></router-link>
                  </div>
                  <div class=""></div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="box contacts">
                  <el-row>
                    <el-col :span="4" >
                      <svg class="icon-svg svg-location">
                        <use xlink:href="#svg-location"></use>
                      </svg>
                    </el-col>
                    <el-col :span="20"
                        class=" in roboto "
                    >
                      <div class="phone">
                        <a href="tel:89779296406">+7 (977) 929 64 06</a>

                      </div>
                      <div class="phone">

                      </div>
                      <div>
                        г.Москва, Ступинский проезд д.7 стр.2 <br>
                        Координаты пункта технического осмотра:
                        Широта: 55.590758  Долгота: 37.631616
                      </div>
                      <div>
                        E-mail:
                        <a href="mailto:ykon2017@mail.ru"
                        >ykon2017@mail.ru</a
                        >
                      </div>
                      <br/>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <div class="col-sm-12 col-md-6">
          <div class="row">
            <div class="col col-sm-7 col-md-6 col-xs-6 foot-xxs-12"></div>
            <div class="col col-sm-5 col-md-6 col-xs-6 foot-xxs-12"></div>
          </div>
        </div>
      </el-row>
    </div>
    <div class="footer_copyright">
      <div class="container">
        <div class="row">
          <div class="col col-lg-7 col-md-8">
                <span class="all_rights"
                >© ООО «ЮКОН», 2020г.
                  </span
                >
          </div>
          <div class="col col-lg-2 col-md-4 alright">
            <span id="bx-composite-banner"></span>
          </div>
          <div class="col col-lg-3 col-md-12 alright"></div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MyFooter"
}
</script>

<style scoped>

</style>