<template>
  <header class="b-header">
    <div class="b-header__logo">
      <router-link class="b-aclogo" to="/">
        <div class="b-aclogo__icon" style="width: 1px">
          <svg class="icon-svg">
            <use xlink:href="#svg-logo_header"></use>
          </svg>
        </div>
        <div class="b-aclogo__text">
          <div class="b-aclogo__title" style="font-size: 25px">
                <span style="color:#bbc0c7 ">Пункт технического осмотра </span
                ><span style="color:#333 ">ООО "ЮКОН"</span>
          </div>
          <div class="b-aclogo__descr"></div>
        </div>
      </router-link>
    </div>
    <div class="b-header__recall">

    </div>
    <div class="b-header__contacts">
      <router-link to="/about"></router-link>
      <div class="b-accontacts">
        <div class="b-accontacts__phone">
          <a href="tel:89779296406">+7 (977) 929 64 06</a>
        </div>
        <div class="b-accontacts__adress">
          г.Москва, Ступинский проезд д.7 стр.2 <br/>
          Координаты пункта технического осмотра:
          Широта: 55.590758 Долгота: 37.631616
        </div>
        <div class="b-accontacts__mail">
          Эл.почта:
          <a href="mailto:ykon2017@mail.ru">ykon2017@mail.ru</a>
        </div>
      </div>
      <br/>
    </div>
  </header>
</template>

<script>
export default {
  name: "MyHeader"
}
</script>

<style scoped>
.b-header {
  display: table;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0 auto;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
</style>