import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Home from '../views/Index.vue'


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Info.vue')
    },
    {
        path: '/docs',
        name: 'Docs',
        component: () => import( '../views/Docs.vue')
    },
    {
        path: '/laws',
        name: 'Laws',
        component: () => import('../views/Code.vue')
    },
    {
        path: '/service/:id',
        name: 'Service',

        component: () => import('../views/Service.vue')
    },
    {
        path: '/services',
        name: 'Services',
        component: () => import('../views/Services.vue')
    },
    {
        path: '/singup',
        name: 'Singup',
        component: () => import('../views/Singup.vue')
    },
    {
        path: '/list',
        name: 'List',
        component: () => import('../views/List.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.replace('/')
export default router
