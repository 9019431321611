import {reactive} from 'vue'

const services = reactive([
{
        name: "Технический осмотр легкового автомобиля",
        startPrice: 1101,
        img: "/img/iblock/656/656195864546b6f051f72c1e78dabd1d.jpg",
        view: true
    },
    {
        name: "Технический осмотр автобуса полной массой до 5 тонн",
        startPrice: 1563,
        img: "/img/iblock/e13/e13616da4cac44f10652436e16314f86.jpg",
        view: true
    },
    {
        name: "Технический осмотр автобуса полной массой свыше 5 тонн",
        startPrice: 1888,
        img: "/img/iblock/c40/c40a20dde69fbd06fe10647d6b389c4a.jpg",
        view: true
    },
    {
        name: "Технический осмотр грузового автомобиля с полной массой до 3.5 тонн",
        startPrice: 999,
        img: "/img/iblock/555/555efb391fc39babd2d310e8fabf5535.jpg",
        view: true
    },
    {
        name: "Технический осмотр грузового автомобиля с полной массой от\n" +
            "                      3.5 до 12 тонн",
        startPrice: 1821,
        img: "/img/iblock/056/0565a8723d5eebcf024be0fad0d667c7.png",
        view: true
    },
    {
        name: "Технический осмотр грузового автомобиля с полной массой свыше 12 тонн",
        startPrice: 1966,
        img: "/img/iblock/eaf/eaf1e547c60777cd46e3f7026375ded0.jpg",
        view: true
    },
    {
        name: "Технический осмотр прицепа с полной массой до 0.75 тонн",
        startPrice: 753,
        img: "/img/iblock/079/0793560e51af984f6315dad4261da30e.jpg",
        view: true
    },
    {
        name: "Технический осмотр прицепа с полной массой от 0.75 до 3.5 тонн",
        startPrice: 753,
        img: "/img/iblock/1ff/1fff46af51a53700003d3cfed8fa59ec.jpg",
        view: true
    },
    {
        name: "Технический осмотр прицепа с полной массой от 3.5 до 10\n" +
            "                      тонн",
        startPrice: 1272,
        img: "/img/iblock/cc6/cc62fac786d906df7d8bbd68af1e2d98.jpg",
        view: true
    },
    {
        name: "Технический осмотр прицепа с полной массой свыше 10 тонн",
        startPrice: 1272,
        img: "/img/iblock/b9a/b9a1405a294ad4fb06e711c7dc8e5775.jpg",
        view: true
    },
    {
        name: "Технический осмотр мототранспортные средств",
        startPrice: "",
        img: "/img/iblock/fdc/fdc9519fafdfeda1635711290dc9d897.jpg",
        view: true
    },
    {
        name: "Технический осмотр среднетоннажных грузовиков",
        startPrice: 1195,
        img: "/img/iblock/photo_2020-12-25_19-30-33.jpg",
        view: false
    },
])
const singupServices = reactive([
    {
        name: "M1 Легковой автомобиль",
        type: "M1",
        view: true
    },
    {
        name: "М2 Автобус массой до 5 т.",
        type: "М2",
        view: true
    },
    {
        name: "М3 Автобус массой более 5 т.",
        type: "М3",
        view: true
    },
    {
        name: "N1 Грузовой автомобиль максимальной массой до 3.5 т.",
        type: "N1",
        view: true
    },
    {
        name: "N2 Грузовые автомобили максимальной массой от 3.5 т. до 12 т.",
        type: "N2",
        view: true
    },
    {
        name: "N3 Грузовые автомобили максимальной массой более 12 т.",
        type: "N3",
        view: true
    },
    {
        name: "О1,О2 Прицеп максимальной массой до 3.5 т.",
        type: "О1,О2",
        view: true
    },
    {
        name: "О3,О4 Прицеп и полуприцеп максимальной массой свыше 3.5 т.",
        type: "О3,О4",
        view: true
    },
    {
        name: "L1 Мототранспортное средство",
        type: "L1",
        view: true
    }
])
const ptoName="ЮКОН"
const apiUrl="https://api.pto-ykon.ru"

export default function myStore() {

    return {
        services,
        singupServices,
        ptoName,
        apiUrl
    }
}