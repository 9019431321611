<template>
  <div class="gmap hidden-xs">
    <div class="block-margin">
      <div class="bx-yandex-view-layout">
        <div class="bx-yandex-view-map gmap-pointer-parent">
          <div
              id="BX_GMAP_main"
              class="bx-google-map"
              style="height: 360px; width: 100%"
          >
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2254.6520913627064!2d37.630233267026426!3d55.59066399025517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414ab20db549bde3%3A0x338f353fa6d3e17a!2z0KHRgtGD0L_QuNC90YHQutC40Lkg0L_RgC3QtCwgNyDRgdGC0YDQvtC10L3QuNC1IDIsINCc0L7RgdC60LLQsCwgMTE3NTQ2!5e0!3m2!1sru!2sru!4v1610969144240!5m2!1sru!2sru" width="100%" height="360" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

          </div>
          <div class="gmap-pointer hidden-xs">
            <div class="gmap-pointer__icon">
              <svg class="icon-svg svg-location">
                <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="#svg-location"
                ></use>
              </svg>
            </div>
            <div class="gmap-pointer__body">
              <strong
              >г.Москва, Ступинский проезд д.7 стр.2</strong
              ><br/>
              График работы:<br/>
              с 9:00 до 18:00 пн.-сб.<br/>
              Воскресенье — по предварительной записи<br/>
              Телефон: 89162771000 <br/>
              <div>
                <a href="mailto:ykon2017@mail.ru"
                >ykon2017@mail.ru</a
                >
              </div>
              <b
              ><span style="color: black"
              ></span
              ></b
              ><br/>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import GMap from "@/components/Ymap";

export default {

  name: "FooterMap",
  components: {},
  setup() {


    return {}
  }
}
</script>

<style scoped>
.gmap-pointer {
  position: absolute;
  top: 50px;
  left: 60%;
  width: 100%;
  max-width: 350px;
  padding: 28px 25px;
  margin: auto;
  margin-left: 15px;
  background-color: #4ba82e;
}
.gmap-pointer:after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  content: ' ';
}
</style>